import React, { useState, useEffect } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

import { Table, Dialog, DialogTitle, DialogContent, DialogActions, Button, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const ScanPopUp = ({openDialog, handleCloseDialog, list}) => {
    return (
        <Dialog fullWidth={true}  maxWidth={"lg"} open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>Guest List</DialogTitle>
            <DialogContent>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Checked In</TableCell>
                        <TableCell align="right">RSVP</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {list.map((row) => (
                        <TableRow
                        key={row.displayname}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {row.displayname}
                        </TableCell>
                        <TableCell align="right">{row.checkedin ? <CheckBoxIcon sx={{color: "green"}}/> : <DisabledByDefaultIcon sx={{color: "red"}}/>}</TableCell>
                        <TableCell align="right">{row.coming}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ScanPopUp
