import React, { useState, useEffect } from 'react';


import { Stack, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField } from '@mui/material';

const ScanPopUp = ({openDialog, handleCloseDialog, handleResult}) => {
    const [code, setCode] = useState("")
    useEffect(()=>{
        setCode("")
    }, [])
    return (
        <Dialog fullWidth={true}  maxWidth={"lg"} open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>Enter Code</DialogTitle>
            <DialogContent>
                <Stack>
                    <Typography>Code</Typography>
                    <TextField value={code} onChange={(e)=>{setCode(e.target.value)}}/>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                    Close
                </Button>
                <Button onClick={()=>{handleResult(code)}} color="primary">
                    Find
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ScanPopUp
