import React, { useState } from 'react';
import { Stack, TextField, Button } from '@mui/material';

const LocalStorageExample = ({handleSave}) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };



  return (
    <Stack sx={{padding: 10}}>
      <TextField
        label="Enter Token"
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
        fullWidth
        style={{ marginBottom: '10px' }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={()=> {handleSave(inputValue)}}
      >
        Save
      </Button>
    </Stack>
  );
};

export default LocalStorageExample;

