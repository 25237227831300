import React, { useState, useEffect } from 'react';


import { Stack, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import {QrScanner} from '@yudiel/react-qr-scanner';

const ScanPopUp = ({openDialog, handleCloseDialog, handleResult}) => {
    const [scannedResult, setScannedResult] = useState();
    const handleError = (err) => {
      console.log("err", err);
    };

useEffect(()=>{
  if (openDialog) {
    setScannedResult()
  }
}, [openDialog])


  useEffect(()=>{
    console.log('<', scannedResult)
    if (scannedResult) {
      handleCloseDialog()
      handleResult(undefined, scannedResult);
    }
  }, [scannedResult])

  
    return (
        <Dialog fullWidth={true}  maxWidth={"lg"} open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>Scan</DialogTitle>
            <DialogContent>
            <QrScanner
                onDecode={(result) => {
                  if (result) {  
                    setScannedResult(result)
                  }
                }}
                onError={(error) => console.log(error?.message)}
            />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
            Close
            </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ScanPopUp
