import React, { useState, useEffect } from 'react';
import ScanPopUp from "./ScanPopUp.js"
import CodePopUp from "./CodePopUp.js"
import ResultPopUp from "./ResultPopUp.js"
import GuestListPopUp from "./GuestListPopUp.js"
import SnackBar from "./Snackbar"
import {Box, Stack, Dialog, Button, Typography } from '@mui/material';


const CounterComponent = ({token, setToken}) => {
    const [guestList, setGuestList ] = useState([])
    const [openDialog, setOpenDialog ] = useState(false)
    const [members, setMembers] = useState(1)
    const [guestDetail, setGuestDetail] = useState(undefined)
    const [openCodeDialog, setOpenCodeDialog ] = useState(false)
    const [openListDialog, setOpenListDialog ] = useState(false)
    const [openResultDialog, setOpenResultDialog ] = useState(false)
    const [snackOpen, setSnackOpen] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [snackType, setSnackType] = useState("success")

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getList = async(_token) => {
    console.log(token)
    const req = await fetch('/api/guestList', {headers:{token:_token || token}})
    if (req.ok) {
      const data = await req.json()
      setGuestList(data)
    } else {
      if (req.status === 401) {
        localStorage.removeItem('token');
        setToken("")
      }
    }
      
  }  
  useEffect(()=>{
    getList()
  }, [token])

  const handleResult = async(code, id) => {
    setOpenDialog(false)
    setOpenCodeDialog(false)
    let url="/api/user"
    if (code || id) {
      if (code) {
        url += `?code=${code}`
      }
      if (id) {
        url += `?id=${id}`
      }
      const req = await fetch(url, {headers:{token:token}})
      if (req.ok) {
        if (req.status === 401) {
          localStorage.removeItem('token');
          setToken("")
        }
        const data = await req.json()
        setGuestDetail(data)
        setMembers(data.noOfGuests)
        setOpenResultDialog(true)
      } else {
        if (req.status === 401) {
          localStorage.removeItem('token');
          setToken("")
        }
      }
    }
  }
  
  const handleCheckin = async() => {
    if (guestDetail && members) {
      if (parseInt(members, 10)) {
        const url =  `/api/checkin/${guestDetail._id}`
        const data = {memberCount: members}
        console.log(data)
        const req = await fetch(url, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            token:token
          }
        })      
        if (req.ok) {
            setOpenResultDialog(false);
            setSnackMessage("User checked in")
            setSnackType("success")
            setSnackOpen(true)
            getList()
        } else {
          if (req.status === 401) {
            localStorage.removeItem('token');
            setToken("")
            return
          }
          if (req.status=== 409) {
            setSnackMessage("User already checked in")
            setSnackType("error")
            setSnackOpen(true)
            console.log("already checked in")
            return
          }
          setSnackMessage("Something went wrong")
            setSnackType("error")
            setSnackOpen(true)
          
        }
      }
    } else {
      setSnackMessage("Enter member count")
      setSnackType("error")
      setSnackOpen(true)

    }
  } 

  return (
    <Box sx={{padding: 5}}>
      <Stack direction="column" sx={{mb: 10}}>
        <Typography sx={{mb:2}} variant="body"> Attendance: {guestList.filter(guest=>guest.checkedin===true).length}/{guestList.length} </Typography>
        <Typography variant="body"> Total Members: {guestList.reduce((acc, curr)=>acc+=curr.memberCount ?? 0, 0)}</Typography>
      </Stack>
      <Stack direction="column" alignItems="space-between" sx={{mb: 10}}>
        <Button sx={{mb: 10}} variant="contained" color="primary" onClick={handleOpenDialog}>
          Scan
        </Button>
        <Button sx={{mb: 10}} variant="contained" color="primary" onClick={()=>{setOpenCodeDialog(true)}}>
          Enter Code
        </Button>
        <Button  sx={{mb: 10}} variant="contained" color="primary" onClick={()=>{setOpenListDialog(true)}}>
            Guest List
          </Button>
      </Stack>
      
      <ScanPopUp handleResult={handleResult} openDialog={openDialog} handleCloseDialog={handleCloseDialog}/>
      <CodePopUp handleResult={handleResult} openDialog={openCodeDialog} handleCloseDialog={()=>{setOpenCodeDialog(false)}}/>
      <GuestListPopUp openDialog={openListDialog} handleCloseDialog={()=>{setOpenListDialog(false)}} list={guestList}/>
      <ResultPopUp handleCheckin={handleCheckin} members={members} setMembers={setMembers} openDialog={openResultDialog} details={guestDetail} handleCloseDialog={()=>{setGuestDetail(null); setOpenResultDialog(false)}}/>
      <SnackBar open={snackOpen} message={snackMessage} severity={snackType} handleClose={()=>{setSnackOpen(false)}}/>
    </Box>
  );
};

export default CounterComponent;

