import React, { useState, useEffect } from 'react';
import UnAuth from "./components/UnAuth.js";
import AuthView from "./components/AuthView.js";

const App = () => {
  const [token, setToken] = useState(undefined);

  useEffect(() => {
    // Check if a specific key exists in local storage
    const _token = localStorage.getItem('token');
    setToken(_token);
  }, []); // Run this effect only once when the component mounts

    const handleSave = (val) => {
            console.log(val)
            localStorage.setItem('token', val);
            setToken(val)
    }

  return (
    <div>
      {token ? (
        // Render components for logged-in user
        <AuthView token={token} setToken={setToken} />
      ) : (
        // Render components for non-logged-in user
        <UnAuth handleSave={handleSave} />
      )}
    </div>
  );
};

const LoggedInView = () => {
  return <div>You are logged in!</div>;
};



export default App;

