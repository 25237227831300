import React, { useState, useEffect } from 'react';


import { Stack, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField } from '@mui/material';


const ResultPopUp = ({openDialog, handleCloseDialog, details, handleCheckin, members, setMembers}) => {
    if (details) {
        return (
            <Dialog fullWidth={true}  maxWidth={"lg"} open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Check in</DialogTitle>
                <DialogContent>
                    <Stack>
                        <Stack direction="row" justifyContent="center" sx={{mb:2}}>
                            <Typography variant='h4'>{details.name}</Typography>
                        </Stack>
                        <Stack direction="row">                            
                            <TextField
                                id="outlined-number"
                                label="Members"
                                type="number"
                                value={members}
                                InputProps={{ inputProps: { min: 1, max: 10 } }}
                                onChange={(e)=>{setMembers(e.target.value)}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                    <Button onClick={handleCheckin} color="primary">
                        Checkin
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
    return null
}

export default ResultPopUp
